import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { NextSeo } from 'next-seo'

import { getEnv } from '@/envs'

export const getCanonicalUrl = (locale: string, asPath: string) => {
  return `${getEnv('NEXT_PUBLIC_HOST_BASE_URL')}/${locale}${asPath.split('?')[0]}`
}

const CanonicalHref = ({ initialCanonical }: { initialCanonical: string }) => {
  const { locale, asPath } = useRouter()
  const [canonicalUrl, setCanonicalUrl] = useState(initialCanonical)

  useEffect(() => {
    const newCanonical = getCanonicalUrl(locale, asPath)
    if (canonicalUrl !== newCanonical) {
      setCanonicalUrl(newCanonical)
    }
  }, [locale, asPath])

  return <NextSeo canonical={canonicalUrl} />
}

export default CanonicalHref
