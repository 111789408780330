import type { PropsWithChildren } from 'react'
import { createContext, useContext } from 'react'

import { keyBy } from 'lodash'
import { useDeepCompareMemo } from 'use-deep-compare'

import type { Category, TypeCategory } from '@/types'
import { isNotEmpty } from '@/utils/plain/isEmpty'

export const ALL_PRODUCTS_CATEGORY_ID = 'all-products'

export type AllProductsCategory = Pick<Category, 'id' | 'order' | 'name'>

export const allProductsCategoryInitialState = {
  id: ALL_PRODUCTS_CATEGORY_ID,
  order: 0,
  name: 'all-products-category',
}

type CategoriesProviderProps = {
  categories: Category[]
  typeCategories: TypeCategory[]
  activeCategory?: Category
  activeCategoryId?: string
  activeTypeCategoryId?: string
  allProductsCategory: AllProductsCategory
}

type CategoriesContextType = {
  categories: Category[]
  categoriesMapById: Record<string, Category>
  typeCategories: TypeCategory[]
  typeCategoriesMapById: Record<string, TypeCategory>
  activeCategory?: Category
  activeTypeCategory?: TypeCategory
  isAllProducts?: boolean
  allProductsCategory: AllProductsCategory
}

const initialState: CategoriesContextType = {
  categories: [],
  categoriesMapById: {},
  typeCategories: [],
  typeCategoriesMapById: {},
  activeCategory: undefined,
  activeTypeCategory: undefined,
  isAllProducts: undefined,
  allProductsCategory: allProductsCategoryInitialState,
}

export const CategoriesContext = createContext(initialState)
export const useCategories = () => useContext(CategoriesContext)

export const CategoriesProvider = (props: PropsWithChildren<CategoriesProviderProps>) => {
  const {
    categories,
    typeCategories,
    activeCategory,
    activeCategoryId,
    activeTypeCategoryId,
    children,
    allProductsCategory,
  } = props

  const memoValue = useDeepCompareMemo(() => {
    const categoriesMapById = keyBy(categories, 'id')
    const typeCategoriesMapById = keyBy(typeCategories, 'id')
    let realActiveCategory = activeCategory

    if (realActiveCategory == null && isNotEmpty(activeCategoryId)) {
      realActiveCategory = categoriesMapById[activeCategoryId]
    }

    const activeTypeCategory = isNotEmpty(activeTypeCategoryId)
      ? typeCategoriesMapById[activeTypeCategoryId]
      : undefined

    const isAllProducts = realActiveCategory == null

    return {
      categories,
      typeCategories,
      activeCategory: realActiveCategory,
      activeTypeCategory,
      isAllProducts,
      allProductsCategory,
      categoriesMapById,
      typeCategoriesMapById,
    }
  }, [
    categories,
    typeCategories,
    activeCategory,
    activeCategoryId,
    activeTypeCategoryId,
    allProductsCategory,
  ])

  return <CategoriesContext.Provider value={memoValue}>{children}</CategoriesContext.Provider>
}
