import { useCurrentRefinements } from 'react-instantsearch'
import { useRouter } from 'next/router'

import { pickBy } from 'lodash'

import { ALGOLIA_FACETS, HIERARCHICAL_CATEGORIES } from '@/constants/algolia'
import { useCategories } from '@/providers/c/CategoriesProvider'
import { useSearchBoxWithInternalState } from '@/providers/c/SearchBoxWithInternalStateProvider'
import type { DownloadableFile, Product } from '@/types'
import { isNotEmpty } from '@/utils/plain/isEmpty'

import { useNormalizedSortBy } from '../useNormalizedSortBy'

export type ProductDetailDataLayerProperties = {
  page_category: 'product_detail_page'
  page_section: 'main'
  page_product_category: string
  page_product_id: string
  page_brand: string
  page_product_type?: string
}

export type ProductCatalogDataLayerProperties = {
  page_category: 'product_catalog'
  page_section: 'main'
  page_product_category?: string
  page_product_type?: string
  page_brand?: string
}

export const defaultValues = {
  page_product_category: 'no_product_category_selected',
  page_product_type: 'no_product_type_selected',
}

export function getDownloadFileProperties(files: DownloadableFile[]) {
  const mappedProps = files.reduce<{ ids: string[]; names: string[] }>(
    (acc, current) => {
      acc.ids.push(current.id)
      acc.names.push(current.name)
      return acc
    },
    {
      ids: [],
      names: [],
    },
  )

  return {
    asset_ids: mappedProps.ids.join(';'),
    asset_names: mappedProps.names.join(';'),
    asset_download_count: String(files.length),
  }
}

export function getProductDetailProperties(product: Product) {
  return pickBy(
    {
      page_category: 'product_detail_page',
      page_section: 'main',
      page_product_category: product.category,
      page_product_type: product.type,
      page_brand: product.brand,
      page_product_id: product.productNr,
    },
    isNotEmpty,
  ) as ProductDetailDataLayerProperties
}

export function useFiltersCustomProperties() {
  const { items } = useCurrentRefinements()

  const division = items.find((i) => i.attribute === ALGOLIA_FACETS.division.facet)
  const areaOfApplication = items.find(
    (i) => i.attribute === ALGOLIA_FACETS.areaOfApplication.facet,
  )

  const availability = items.find((i) => i.attribute === ALGOLIA_FACETS.storeAvailability.facet)

  const { currentRefinement, originalItems } = useNormalizedSortBy()

  const sortingLabel = originalItems.find((i) => i.value === currentRefinement)?.label

  const search = useSearchBoxWithInternalState()

  return pickBy(
    {
      filter_division: division?.refinements.map((r) => r.value).join(';'),
      filter_application_scope: areaOfApplication?.refinements.map((r) => r.value).join(';'),
      filter_availability: availability?.refinements.map((r) => r.value).join(';'),
      filter_sorting: sortingLabel,
      filter_search: search.value,
    },
    isNotEmpty,
  )
}

export function useCatalogCustomPropertiesFromAlgolia() {
  const { items } = useCurrentRefinements({
    includedAttributes: [HIERARCHICAL_CATEGORIES[0], ALGOLIA_FACETS.division.facet],
  })

  const categories = useCategories()

  const divisionItem = items.find((i) => i.attribute === ALGOLIA_FACETS.division.facet)

  const division = divisionItem?.refinements.map((r) => r.value).join(';')

  const nonEmptyProperties = pickBy(
    {
      page_category: 'product_catalog',
      page_section: 'main',
      page_product_type: categories.activeTypeCategory?.name,
      page_product_category: categories.activeCategory?.name,
      page_brand: division,
    },
    isNotEmpty,
  ) as ProductCatalogDataLayerProperties

  return nonEmptyProperties
}

export function usePageChangeParameters(
  customProperties: ProductCatalogDataLayerProperties | ProductDetailDataLayerProperties,
) {
  const router = useRouter()
  const pathWithoutParams = router.asPath.split('#')[0].split('?')[0]
  const productSlug = pathWithoutParams.split('-')
  const productNumber = productSlug[productSlug.length - 1]

  return {
    pathWithoutParams:
      customProperties.page_category === 'product_catalog' ? productNumber : pathWithoutParams,
    pageProductType: customProperties.page_product_type,
  }
}
