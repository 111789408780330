import DOMPurify from 'isomorphic-dompurify'

type WrapperTextComponentType = {
  Component: React.ElementType
  content: string
  [key: string]: any
}

const allowedTags = ['strong', 'i', 'u', 'sup', 'sub', 'p']

const allowHtmlTags = (content: string) => {
  return DOMPurify.sanitize(content, { ALLOWED_TAGS: allowedTags })
}

const WrapperTextComponent = ({ Component, content, ...props }: WrapperTextComponentType) => {
  return <Component dangerouslySetInnerHTML={{ __html: allowHtmlTags(content) }} {...props} />
}

export default WrapperTextComponent
